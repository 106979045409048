<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          :to="{name:'home'}"
        >
          <vuexy-logo />
        </b-link>

        <b-card-title class="mb-1 text-center">
          Registro
        </b-card-title>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-row>
              <b-col
                sm="12"
                md="6"
              >
                <!-- ruc -->
                <b-form-group
                  label="RUC"
                  label-for="ruc"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="RUC"
                    rules="required|ruc"
                    vid="ruc"
                  >
                    <b-form-input
                      id="ruc"
                      v-model="ruc"
                      :state="errors.length > 0 ? false:null"
                      :formatter="formatterRuc"
                      name="ruc"
                      placeholder="Ejm: 20123456789"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- userName -->
                <b-form-group
                  label="Razón social o nombre"
                  label-for="userName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Razón social o nombre"
                    rules="required|max:100"
                    vid="businessName"
                  >
                    <b-form-input
                      id="userName"
                      v-model="userName"
                      :state="errors.length > 0 ? false:null"
                      name="userName"
                      placeholder="Ejm: Empresa 123 S.A."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- names and lastNames -->
                <b-form-group
                  label="Nombres y apellidos del contacto"
                  label-for="names"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nombres y apellidos"
                    rules="required|max:100"
                    vid="names"
                  >
                    <b-form-input
                      id="names"
                      v-model="names"
                      :state="errors.length > 0 ? false:null"
                      name="names"
                      placeholder="Ejm: Carlos Perez Diaz"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- roles -->
                <b-form-group
                  label="Cargo del contacto"
                  label-for="role"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cargo del contacto"
                    rules="required"
                    vid="role"
                  >
                    <b-form-input
                      id="role"
                      v-model="role"
                      :state="errors.length > 0 ? false:null"
                      name="role"
                      placeholder="Ejm: Administrador"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
              >
                <!-- tel -->
                <b-form-group
                  label="Teléfono de representante legal"
                  label-for="tel"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="teléfono"
                    rules="required|phone:0"
                    vid="tel"
                  >
                    <b-form-input
                      id="tel"
                      v-model="tel"
                      :state="errors.length > 0 ? false:null"
                      :formatter="formatterTel"
                      name="tel"
                      placeholder="Ejm: 987654321"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- email -->
                <b-form-group
                  label="Correo electrónico"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="correo electrónico"
                    rules="required|email"
                    vid="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="regEmail"
                      :state="errors.length > 0 ? false:null"
                      name="register-email"
                      placeholder="Ejm: usuario@ejemplo.pe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- password -->
                <b-form-group
                  label="Crear Contraseña"
                  label-for="password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="contraseña"
                    rules="required|password"
                    vid="password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- rePassword -->
                <b-form-group
                  label="Confirmar contraseña"
                  label-for="rePassword"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="confirmar contraseña"
                    rules="required|confirmed:password"
                    vid="rePassword"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="rePassword"
                        v-model="rePassword"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="rePassword"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="6"
              >
                <!-- checkbox terms -->
                <b-form-group>
                  <b-form-checkbox
                    id="terms"
                    v-model="terms"
                    name="terms"
                    required
                  >
                    Acepto
                    <b-link
                      target="_blank"
                      href="https://www.efide.com.pe/wp-content/uploads/ef/EF_PD_Terminos_y_Condiciones.pdf"
                    >
                      términos y condiciones
                    </b-link>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <!-- checkbox policy -->
                <b-form-group>
                  <b-form-checkbox
                    id="policy"
                    v-model="policy"
                    name="policy"
                    required
                  >
                    Acepto
                    <b-link
                      target="_blank"
                      href="https://www.efide.com.pe/wp-content/uploads/ef/EF_PD_Politicas_Proteccion_Datos.pdf"
                    >
                      politicas de protección de datos
                    </b-link>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="6"
              >
                <!-- return button -->
                <b-button
                  class="my-1"
                  variant="secondary"
                  :to="{name:'auth-login'}"
                  block
                >
                  Volver
                </b-button>
              </b-col>
              <b-col
                sm="12"
                md="6"
              >
                <!-- submit button -->
                <b-button
                  class="my-1"
                  variant="primary"
                  block
                  type="submit"
                >
                  Registrarse
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          <span>¿Ya tienes una cuenta? </span>
          <b-link :to="{name:'auth-login'}">
            <span>Iniciar sesión</span>
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ruc: '',
      clientType: 0,
      regEmail: '',
      userName: '',
      names: '',
      role: '',
      tel: '',
      password: '',
      rePassword: '',
      terms: false,
      policy: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    ruc() {
      if (this.ruc.substring(0, 2) === '10') {
        this.clientType = 0
      } else if (this.ruc.substring(0, 2) === '20') {
        this.clientType = 1
      }
    },
  },
  methods: {
    formatterRuc(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            ruc: this.ruc,
            clientType: this.clientType,
            email: this.regEmail,
            businessName: this.userName,
            names: this.names,
            role: this.role,
            tel: this.tel,
            password: this.password,
            rePassword: this.rePassword,
          })
            .then(response => {
              const { user } = response.data
              localStorage.setItem('userData', JSON.stringify(user))
              localStorage.setItem('clientType', user.clientType)
              localStorage.setItem('type', '1')
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.token)
              store.dispatch('elementVerticalMenu/getData')
              this.$router.replace(getHomeRouteForLoggedInUser(user))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Bienvenido ${user.names}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Has iniciado sesión correctamente como ${user.role}.${user.registerComplete ? ' ¡Ahora debes completar tu registro!' : ''}`,
                    },
                  },
                  {
                    timeout: 8000,
                  })
                })
            })
            .catch(error => {
              console.log('errores', error.response.data.error)
              this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
